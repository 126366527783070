var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-table"},[_c('div',{staticClass:"table-filters text-right"},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.filterBy")))]),_c('div',{staticClass:"d-inline-block ml-5",staticStyle:{"width":"250px"}},[_c('date-range-picker',{attrs:{"textFieldAttrs":{
          solo: true,
          placeholder: _vm.$t('labels.date'),
        },"on":{
          change: _vm.fetchData,
        }},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1),_c('v-select',{staticClass:"d-inline-block ml-5",staticStyle:{"width":"230px"},attrs:{"items":_vm.stateItems,"placeholder":_vm.$t('labels.state'),"clearable":"","solo":""},on:{"change":_vm.fetchData},model:{value:(_vm.filters.state),callback:function ($$v) {_vm.$set(_vm.filters, "state", $$v)},expression:"filters.state"}})],1),_c('v-row',[_c('v-col',{staticClass:"pr-7"},[_c('v-data-table',{staticClass:"row-clickable",attrs:{"headers":_vm.headers,"items":_vm.$store.getters['orders/items'],"items-per-page":_vm.per_page,"page":_vm.page,"loading":_vm.$store.getters['orders/loading'].fetchAll,"server-items-length":_vm.$store.getters['orders/total'],"item-class":function (item) { return _vm.selectedItem && _vm.selectedItem.id === item.id ? 'row-selected' : ''; },"footer-props":_vm.$config.vDataTable.footerProps,"disable-sort":""},on:{"update:itemsPerPage":function($event){_vm.per_page=$event},"update:items-per-page":[function($event){_vm.per_page=$event},_vm.fetchData],"update:page":[function($event){_vm.page=$event},_vm.fetchData],"click:row":function (item) { return (_vm.selectedItem = item); }},scopedSlots:_vm._u([{key:"item.price.value",fn:function(ref){
            var item = ref.item;
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatMoney(value, item.price.currency))+" ")]}},{key:"item.created_at",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatDate(value))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"loading":_vm.$store.getters['orders/loading'].downloadPdf ===
              item.order_number,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('orders/downloadPdf', item.order_number)}}},[_c('v-icon',[_vm._v("mdi-file-download-outline")])],1)]}}])})],1),(_vm.selectedItem)?_c('v-col',{staticClass:"order-preview-col pl-7",attrs:{"cols":"auto"}},[_c('OrderPreview',{attrs:{"order":_vm.selectedItem}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }