var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-table-preview",staticStyle:{"width":"200px"}},[_c('h3',[_vm._v("#"+_vm._s(_vm.order.order_number))]),_c('OrderState',{staticClass:"text--secondary",attrs:{"order":_vm.order}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.order.rights_bundle.product.title))]),_c('v-img',{staticClass:"rounded",attrs:{"width":"140","height":"180","src":_vm.image}}),_c('small',{staticClass:"d-block mb-3 mt-1"},[_c('router-link',{staticClass:"text--secondary basic-link",attrs:{"to":{
        name: 'app.products.view',
        params: { id: _vm.order.rights_bundle.product_id },
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("labels.productDetails"))+" ")])],1),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.$t("labels.price"))+":")]),_vm._v(" "+_vm._s(_vm.$formatMoney(_vm.order.price.value, _vm.order.price.currency))+" ")]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.$t("labels.orderDate"))+":")]),_vm._v(" "+_vm._s(_vm.$formatDate(_vm.order.created_at))+" ")]),_c('v-btn',{attrs:{"color":"primary","block":"","link":"","to":{
      name: _vm.$isAdmin() ? 'admin.orders.view' : 'app.orders.view',
      params: { id: _vm.order.id },
    }}},[_vm._v(" "+_vm._s(_vm.$t("buttons.details"))+" ")]),(_vm.$organisation && _vm.order.organisation_id === _vm.$organisation.id)?_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","block":"","link":"","to":{
      name: 'app.checkout',
      params: { number: _vm.order.order_number },
    }}},[_vm._v(" "+_vm._s(_vm.$t("checkout.checkout"))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }